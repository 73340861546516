<template>
  <div>
    <div class="topimg">
      <img src="../../../assets/masterStation/03_02.png" alt="">
    </div>
    <div class="caesConter">
      <div class="box">
        <img src="../../../assets/masterStation/03_05.png" alt="">
        <div class="caesConter_txt">
          <h4>案例 中心
            <span>CASE CENTER</span>
          </h4>
          <p>
            遵义市教育云大数据分析平台 <br />
            铜仁智慧教育监管中心<br />
            道真县教育质量监测中心<br />
            贵阳市理化生实验考试平台<br />
            六盘水市智慧教育<br />
            遵义市教育科学研究院名师题库<br />
            平塘县名师工作室<br />
            赤水市教育资源云平台<br />
            ···
          </p>

        </div>
      </div>
    </div>
    <!-- 客户特辑 -->
    <div class="customer">
      <div class="box">
        <div class="customerTxt">
          <p>客户案例特辑</p>
          <div>
            <i class="iconfont icon-zuo " @click="prev()"></i>
            <i class="iconfont icon-zuo " style="transform: rotateY(180deg);" @click="next()"></i>
          </div>
        </div>
        <el-carousel height="240px" type='card' :interval='5000' indicator-position='none' ref='caseBanner' arrow="always" loop>
          <el-carousel-item v-for="(item,index) in customerlist" :key="index">
            <div class="case_item">
              <img :src="item.icon" alt="柒彩科技">
              <div class="case_box">
                <span> {{ setitem(index + 1) }} </span>
                <div class="case_txt">{{item.txt}} </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 地图分布 -->
    <div class="map">
      <div class="box">
        <h3 class="map_title">案例分布图
          <span>CASE DISTRIBUTION</span>
        </h3>
        <div class="map_box">
          <img style="max-width:100%;margin:0 auto;" src="@/assets/masterStation/图片9.png" alt="">
          <!-- <Vmap></Vmap> -->
        </div>
        <div class="map_txt">
          <h3>遵义市教育云</h3>
          <p>遵义市教育与建设时间，目的等简介遵义市教育与建设时间,目的等简介义市教育与建设时间,目的等简介介市教育与建设时间,目的等简介介绍教育与建设时间,目的介介。</p>
          <p>遵义市教育与建设时间，目的等简介遵义市教育与建设时间，目的等简介义市教育与建设时间。</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import Vmap from '../component/map.vue'
export default {
  // components: { Vmap },
  data () {
    return {
      customerlist: [
        { icon: require('@/assets/masterStation/图片6.png'), txt: '智慧教育质量检测' },
        { icon: require('@/assets/masterStation/图片7.png'), txt: '智慧教育质量检测' },
        { icon: require('@/assets/masterStation/图片8.png'), txt: '智慧教育质量检测' },
        { icon: require('@/assets/masterStation/图片1.png'), txt: '智慧教育质量检测' },
        { icon: require('@/assets/masterStation/图片2.png'), txt: '智慧教育质量检测' },
        { icon: require('@/assets/masterStation/图片4.png'), txt: '智慧教育质量检测' },
        { icon: require('@/assets/masterStation/图片5.png'), txt: '智慧教育质量检测' },
        { icon: require('@/assets/masterStation/图片3.png'), txt: '智慧教育质量检测' }
      ]
    }
  },
  mounted () {},
  methods: {
    // 客户数字小于10 的前加0
    setitem (i) {
      return i < 10 ? '0' + i : i
    },

    // 客户轮播上 一张
    prev () {
      this.$refs.caseBanner.prev()
    },
    // 客户轮播下 一张
    next () {
      this.$refs.caseBanner.next()
    }
  }
}
</script>
<style lang="less" scoped>
.topimg {
  img {
    width: 100%;
  }
}
.caesConter {
  height: 650px;
  background: linear-gradient(-45deg, #d2f1eb 20%, #eff4f8 30%);
  box-sizing: border-box;
  padding-top: 60px;
  .box {
    display: flex;
    justify-content: center;
  }
  img {
    position: relative;
    z-index: 3;
    width: 470px;
    height: 335px;
  }
  .caesConter_txt {
    width: 620px;
    height: 720px;
    background: #fff;
    margin-top: -260px;
    margin-left: -30px;
    box-sizing: border-box;
    padding-left: 170px;
    padding-top: 55px;
    h4 {
      font-size: 50px;
      margin-bottom: 60px;
      span {
        display: block;
        font-size: 28px;
        color: #989898;
      }
    }
    p {
      line-height: 40px;
      font-size: 18px;
    }
  }
}
.customer {
  height: 590px;
  display: flex;
  align-items: center;
  background: url("../../../assets/masterStation/03_19.png") top left/100% 100%
    no-repeat;
  .box {
    width: 100%;
    .customerTxt {
      font-size: 36px;
      color: #fff;
      margin-bottom: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      i {
        font-size: 40px;
        display: inline-flex;
        border-radius: 50%;
        border: 2px solid rgba(255, 255, 255, 0.3);
        align-items: center;
        justify-content: center;
        margin: 0 15px;
        width: 54px;
        height: 54px;
      }
    }
  }
  /deep/.el-carousel__mask {
    background-color: transparent;
  }
  /deep/ .el-carousel__arrow {
    display: none !important;
  }
  .case_item {
    width: 390px;
    height: 240px;
    margin: 0 auto;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .case_box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 30px;
      display: flex;
      align-content: space-between;
      flex-wrap: wrap;
      span {
        font-size: 30px;
        color: #fff;
      }
      .case_txt {
        font-size: 18px;
        color: #e6e6e6;
        width: 100%;
      }
    }
  }
}

.map {
  width: 100%;
  min-height: 600px;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(-45deg, #d2f1eb 20%, #eff4f8 30%);
  position: relative;
  .box {
    width: 100%;
    position: relative;
    .map_title{
        font-size: 50px;
        position: absolute;
        z-index:3;
        top: 5%;
        left: 0;
        font-weight: 500;
        color: #161B1F;
        span{
          display: block;
          font-size: 20px;
          color: #989898;
        }
    }
    .map_txt{
      background-color: #343A46;
      width: 340px;
      box-sizing: border-box;
      padding:0 30px 10px;
      position:absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      color: #e6e6e6;
      font-size: 16px;
      line-height: 30px;
      h3{
        color: #fff;
        font-weight: 500;
        width: calc(  100% + 60px);
        height: 60px;
        margin-bottom: 30px;
        background-color: rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        margin-left: -45px;
        margin-top: -15px;
        padding: 23px 0 15px 40px;
      }
      p{
        padding-bottom: 30px;
      }
    }
  }
}
</style>
