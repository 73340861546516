import { render, staticRenderFns } from "./case.vue?vue&type=template&id=da49bcaa&scoped=true&"
import script from "./case.vue?vue&type=script&lang=js&"
export * from "./case.vue?vue&type=script&lang=js&"
import style0 from "./case.vue?vue&type=style&index=0&id=da49bcaa&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da49bcaa",
  null
  
)

export default component.exports